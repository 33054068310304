import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Tracker } from '@yoursurprise/segment-analytics';
import type { UiState } from 'instantsearch.js';
import App from './Components/App';
import { document, window } from '../../../js/globals';
import { createFacetedSearchClient } from '../general/searchClient';
import slugify from '../../../js/util/slugify';
import updateSortBy from '../general/url/updateSortBy';
import { urlToUiState } from '../general/url/urlToUiState';

const container = document?.getElementById('product-category-page');

const { ALGOLIA_DATA, ALGOLIA_SERVER_DATA, PRODUCT_CATEGORY_ALGOLIA_DATA, pageType, siteMetadata } = window as Window;

const pageId = document?.getElementById('pageIdentifier')?.innerHTML;

if (container && pageId && PRODUCT_CATEGORY_ALGOLIA_DATA) {
    const pageTypeSlug = slugify(pageType ?? 'Other');

    const searchClient = createFacetedSearchClient(
        PRODUCT_CATEGORY_ALGOLIA_DATA?.appID ?? '',
        PRODUCT_CATEGORY_ALGOLIA_DATA?.searchApiKey ?? '',
    );

    const tracker = new Tracker();
    if (ALGOLIA_DATA) {
        const uiState: UiState = {
            [ALGOLIA_DATA.productIndexName]: updateSortBy(
                urlToUiState(window?.location.search ?? ''),
                ALGOLIA_DATA.productIndexName,
                ALGOLIA_DATA.replicaIndexBase,
            ),
        };

        const algoliaServerData = PRODUCT_CATEGORY_ALGOLIA_DATA.usesAlgolia ? ALGOLIA_SERVER_DATA : { initialResults: {} };

        hydrateRoot(
            container,
            <BrowserRouter>
                <App
                    initialUiState={uiState}
                    algoliaServerState={algoliaServerData}
                    pageTypeSlug={pageTypeSlug}
                    contextData={siteMetadata}
                    currency={PRODUCT_CATEGORY_ALGOLIA_DATA.currency}
                    components={PRODUCT_CATEGORY_ALGOLIA_DATA.components}
                    locale={PRODUCT_CATEGORY_ALGOLIA_DATA.locale}
                    pageId={pageId ? Number(pageId) : 0}
                    photoGiftUrl={PRODUCT_CATEGORY_ALGOLIA_DATA.photoGiftUrl}
                    productIndexName={PRODUCT_CATEGORY_ALGOLIA_DATA.productIndexName}
                    replicaIndexBase={PRODUCT_CATEGORY_ALGOLIA_DATA.replicaIndexBase}
                    searchClient={searchClient}
                    translations={PRODUCT_CATEGORY_ALGOLIA_DATA.translations}
                    tracker={tracker}
                />
            </BrowserRouter>,
        );

        delete (window as Window).ALGOLIA_SERVER_DATA;
        delete (window as Window).PRODUCT_CATEGORY_ALGOLIA_DATA;
    }
}
